import { Component, ChangeDetectionStrategy } from '@angular/core';
import { UserType } from '@gymautoc/common/core/enums/user-type';
import { AuthService } from '@gymautoc/common/core/services/auth.service';
import { CurrentUserService } from '@gymautoc/common/core/services/current-user.service';
import { LoadingService } from '@gymautoc/common/core/services/loading.service';
import { DestroyableComponent, takeUntilDestroy } from '@gymautoc/common/core/utils/destroyable';
import { map, switchMap } from 'rxjs/operators';

/** Component with information about current user. */
@Component({
  selector: 'gymautow-user-info',
  templateUrl: './user-info.component.html',
  styleUrls: ['./user-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
@DestroyableComponent()
export class UserInfoComponent {
  /** User. */
  public readonly user$ = this.userService.currentUser$;

  /** Change displayed name to 'Super Admin' if user is Admin. */
  public readonly displayedName$ = this.user$.pipe(
    map(user =>
      user?.type === UserType.Admin ? 'Super Admin' : `${user?.firstName} ${user?.type}`,
    ),
  );

  constructor(
    private readonly userService: CurrentUserService,
    private readonly authService: AuthService,
    private readonly loadingService: LoadingService,
  ) {}

  /** Logout user */
  public logout(): void {
    const loaderId = this.loadingService.generateId('loading');
    this.loadingService
      .start(loaderId)
      .pipe(
        switchMap(() => this.authService.logout()),
        this.loadingService.finishInAnyCases(loaderId),
        takeUntilDestroy(this),
      )
      .subscribe();
  }
}
