import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, NavigationEnd, Router } from '@angular/router';
import { CurrentUserService } from '@gymautoc/common/core/services/current-user.service';
import { BehaviorSubject } from 'rxjs';
import { filter, first } from 'rxjs/operators';

import { Breadcrumb } from './breadcrumb';

/**
 * Class for working with Breadcrumbs
 *
 * This service automatically generates breadcrumbs for current route.
 * Routes with breadcrumbs should has additional data in definition:
 *
 *    {
 *      path: 'users',
 *      data: { breadcrumb: 'Users'},
 *      children: [
 *        {
 *          path: '',
 *          pathMatch: 'full',
 *          component: UserProfilePageComponent,
 *        },
 *        {
 *          path: 'edit',
 *          component: UserEditPageComponent,
 *          data: { breadcrumb: 'User Name', breadcrumbId: 'user-name' },
 *        },
 *      ],
 *    }
 *
 * Param `breadcrumbId` allows you to change breadcrumb text dynamically in runtime
 * with `changeBreadcrumbById` method:
 *
 *    this.breadcrumbsService.changeBreadcrumbById('user-name', 'John Doe');
 */
@Injectable({
  providedIn: 'root',
})
export class BreadcrumbsService {
  private readonly breadcrumbsValue$ = new BehaviorSubject<Breadcrumb[]>([]);

  /** Reactive breadcrumbs value */
  public readonly breadcrumbs$ = this.breadcrumbsValue$.asObservable();

  /** List of all breadcrumbs */
  private breadcrumbs = new Array<Breadcrumb>();

  /** Cached route names */
  private readonly cachedRouteNames = new Map<string, string>();

  public constructor(
    private readonly router: Router,
    private readonly currentUserService: CurrentUserService,
  ) {
    // Listen router events
    this.router.events.pipe(
      filter(routerEvent => routerEvent instanceof NavigationEnd),
    ).subscribe(() => this.processRoute(router.routerState.root.snapshot));

    // Initial snapshot
    this.currentUserService.currentUser$.pipe(
      first(),
    ).subscribe(() => this.processRoute(router.routerState.root.snapshot));
  }

  /**
   * Change breadcrumb value by id
   *
   * @param id id of breadcrumb (specified in route data)
   * @param name breadcrumb name, to replace breadcrumb with specified id.
   */
  public changeBreadcrumbById(id: string, name: string): void {
    const breadcrumb = this.breadcrumbs.find((bc) => bc.id === id);
    if (breadcrumb) {
      breadcrumb.displayName = name;
      this.updateValue();
    }
    this.cachedRouteNames.set(id, name);
  }

  private updateValue(crumbs?: Breadcrumb[]): void {
    if (crumbs) {
      this.breadcrumbs = crumbs;
    }
    this.breadcrumbsValue$.next(this.breadcrumbs);
  }

  private processRoute(route: ActivatedRouteSnapshot): void {
    let url = '';

    let breadCrumbIndex = 0;
    const newCrumbs = [];

    while (route.firstChild != null) {
      route = route.firstChild;

      if (route.routeConfig === null || !route.routeConfig.path || !route.data['breadcrumb']) {
        continue;
      }

      url += `/${createRouteUrl(route)}`;

      const breadcrumbId = route.data['breadcrumbId'];

      const newCrumb = new Breadcrumb({
        id: breadcrumbId,
        displayName: route.data['breadcrumb'],
        terminal: isTerminal(route),
        url: url,
        route: route.routeConfig,
      });

      this.processRoutecached(breadcrumbId, newCrumb);

      if (breadCrumbIndex < this.breadcrumbs.length) {
        const existing = this.breadcrumbs[breadCrumbIndex++];

        if (existing && existing.route === route.routeConfig) {
          newCrumb.displayName = existing.displayName;
        }
      }

      newCrumbs.push(newCrumb);
    }

    this.updateValue(newCrumbs);
  }

  private processRoutecached(breadcrumbId: any, newCrumb: Breadcrumb): void {
    // Try to set cached Name for dynamic route
    if (breadcrumbId) {
      const cached = this.cachedRouteNames.get(breadcrumbId);
      if (cached) {
        newCrumb.displayName = cached;
        this.cachedRouteNames.delete(breadcrumbId);
      }
    }
  }

}

/**
 * Check that route is terminal
 *
 * @param route route to check
 */
function isTerminal(route: ActivatedRouteSnapshot): boolean {
  return (
    route.firstChild === null ||
    route.firstChild.routeConfig === null ||
    !route.firstChild.routeConfig.path
  );
}

/**
 * Create url for activate route snapshot
 *
 * @param route route generate url
 */
function createRouteUrl(route: ActivatedRouteSnapshot): string {
  return route.url.map((s) => s.toString()).join('/');
}
