import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap, first } from 'rxjs/operators';

import { AppConfigService } from '../services/app-config.service';

/**
 * Interceptor to add access token to requests.
 */
@Injectable()
export class RefreshTokenInterceptor implements HttpInterceptor {
  /** Request to refresh token. */
  private refreshTokenRequest$: Observable<string> | null = null;

  /**
   * @constructor
   * @param appConfigService - Configuration service.
   */
  public constructor(
    private appConfigService: AppConfigService,
  ) { }

  /**
   * Refreshes a token.
   * @inheritdoc
   */
  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req)
      .pipe(
        catchError(error => {
          if (error.status !== 401) {
            return throwError(error);
          }
          if (this.shouldRefreshToken(req.url)) {
            return throwError(error);
          }
          this.refreshTokenRequest$ =
            this.refreshTokenRequest$ || this.requestRefreshToken();
          return this.refreshTokenRequest$.pipe(
            switchMap(token => {
              // TODO (template preparation): Add new token to current request.
              return this.updateToken(token)
                .pipe(
                  first(),
                  switchMap(() => next.handle(req)),
                );
            }),
          );
        }),
      );
  }

  private updateToken(_token: string): Observable<void> {
    throw new Error('Not implemented'); // TODO (template preparation)
  }

  /**
   * Checks if a request is for authorization or refresh token.
   * @param url - Request url.
   */
  private shouldRefreshToken(url: string): boolean {
    return url.startsWith(this.appConfigService.apiUrl) && !url.startsWith(`${this.appConfigService.apiUrl}/auth`);
  }

  private requestRefreshToken(): Observable<string> {
    throw new Error('Not implemented'); // TODO (template preparation)
  }
}
