import {
  Component,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Optional,
  Self,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { FormControl, NgControl } from '@angular/forms';
import { Instructor } from '@gymautoc/common/core/models/instructor';
import { listenControlChanges } from '@gymautoc/common/core/rxjs/listen-control-changes';
import { ControlValueAccessorBase } from '@gymautoc/common/core/utils/control-value-accessor-base';
import { DestroyableComponent, takeUntilDestroy } from '@gymautoc/common/core/utils/destroyable';
import { createTrackByPropertyFunction } from '@gymautoc/common/core/utils/track-by-property';

/** Instructor select component. */
@Component({
  selector: 'gymautoc-instructor-select',
  styleUrls: ['./instructor-select.component.scss'],
  templateUrl: './instructor-select.component.html',

  // We need Default change detection to properly display errors.
  changeDetection: ChangeDetectionStrategy.Default,
})
@DestroyableComponent()
export class InstructorSelectComponent extends ControlValueAccessorBase<readonly Instructor[]> {
  /** Form control label. */
  @Input()
  public label: string | null = null;

  /** Form control placeholder. */
  @Input()
  public placeholder = 'Select instructor';

  /** Is required. */
  @Input()
  public required = false;

  /** Is readonly. */
  @Input()
  public readonly = false;

  /** Instructor list. */
  @Input()
  public instructorList: readonly Instructor[] | null = [];

  /** Is loading data. */
  @Input()
  public loading = false;

  /** Is search loading data. */
  @Input()
  public searchLoading = false;

  /** Include empty option. */
  @Input()
  public includeEmptyOption = false;

  /** On search change. */
  @Output()
  public readonly onSearchChange = new EventEmitter<string>();

  /** On open/close select. */
  @Output()
  public readonly onOpenChange = new EventEmitter<boolean>();

  /** Search control. */
  public searchControl: FormControl = new FormControl('');

  /** Is form control disabled. */
  public get isDisabled(): boolean {
    return this.readonly || Boolean(this.control.disabled);
  }

  /** From control. */
  public get formControl(): FormControl {
    return this.control.control as FormControl;
  }

  /** @constructor */
  public constructor(
    cdr: ChangeDetectorRef,
    @Self() @Optional() private readonly control: NgControl,
  ) {
    super(cdr);
    this.control.valueAccessor = this;
  }

  /** @inheritdoc */
  public ngOnInit(): void {
    this.subscribeToChangeSearch();
  }

  /** Track by instructor id */
  public readonly trackByInstructorId = createTrackByPropertyFunction<Instructor>('id');

  private subscribeToChangeSearch(): void {
    listenControlChanges<string>(this.searchControl)
      .pipe(takeUntilDestroy(this))
      .subscribe(value => this.onSearchChange.emit(value));
  }
}
