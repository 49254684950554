<mat-form-field *ngIf="formControl" (click)="!isDisabled && datepicker.open()">
  <mat-label *ngIf="label">{{ label }}</mat-label>
  <input
    matInput
    readonly
    [max]="max"
    [min]="min"
    [required]="required"
    [placeholder]="placeholder"
    [formControl]="formControl"
    [matDatepicker]="datepicker"
    (focus)="!isDisabled && datepicker.open()">
  <mat-datepicker-toggle matSuffix [for]="datepicker" [disabled]="isDisabled"></mat-datepicker-toggle>
  <mat-datepicker #datepicker
    (opened)="formControl.setErrors(null)"
    (closed)="formControl.updateValueAndValidity()">
  </mat-datepicker>
  <mat-error>
    <gymautoc-form-control-validation-message [control]="formControl">
    </gymautoc-form-control-validation-message>
  </mat-error>
</mat-form-field>
