import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanLoad, Data, Route, Router, UrlTree } from '@angular/router';
import { UserType } from '@gymautoc/common/core/enums/user-type';
import { CurrentUserService } from '@gymautoc/common/core/services/current-user.service';
import { mainPageByUserType, routePaths } from '@gymautoc/common/core/utils/route-paths';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';

/**
 * Guard that checks user type.
 *
 * Available roles should be in data.userTypes field. If none userTypes specified, no users can enter.
 *
 * {
 *   path: 'users',
 *   canActivate: [RestrictRolesGuard],
 *   data: {
 *     userTypes: [UserType.Admin, UserType.Assistant],
 *   },
 *   ...,
 * },
 *
 * If in route data specified "permissionErrorMessage" key, this message will show if access denied.
 */
@Injectable({
  providedIn: 'root',
})
export class RestrictUserTypesGuard implements CanActivate, CanLoad {
  /**
   * @constructor
   */
  public constructor(
    protected readonly currentUserService: CurrentUserService,
    protected readonly router: Router,
  ) {
  }

  /** Get user types. */
  protected getUserTypes(data?: Data): UserType[] {
    return data?.userTypes || [];
  }

  /** Get error message. */
  protected getErrorMessage(data?: Data): string {
    return data?.permissionErrorMessage;
  }

  /**
   * Determine if route could be achieved
   * @param route Activated route.
   */
  public canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    return this.commonRouteActivateOrLoadCheck(route);
  }

  /**
   * Determine if route could be loaded.
   * @param route Route.
   */
  public canLoad(route: Route): Observable<boolean | UrlTree> {
    return this.commonRouteActivateOrLoadCheck(route);
  }

  /**
   * Check that user accept terms.
   */
  protected checkRole(userTypes: UserType[]): Observable<boolean | UrlTree> {
    return this.currentUserService.currentUser$.pipe(
      first(),
      map((user) => {
        const userType = user?.type;
        if (userType && userTypes.includes(userType)) {
          return true;
        }

        const redirectLink = userType ? mainPageByUserType[userType] : routePaths.login;
        // Access denied, return user to home page
        return this.router.createUrlTree(redirectLink);
      }),
    );
  }

  /**
   * Common function to check for route activate or route load.
   * @param route Activated Route or Route.
   */
  private commonRouteActivateOrLoadCheck(route: ActivatedRouteSnapshot | Route): Observable<boolean | UrlTree> {
    const types = this.getUserTypes(route.data);
    return this.checkRole(types);
  }
}
