import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { ListOptions, ListOptionType } from '@gymautoc/common/core/models/list-options';

/**
 * List component with dot and option.
 */
@Component({
  selector: 'gymautoc-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListComponent<T extends {}> {
  /** Additional option for element row. */
  @Input()
  public option: ListOptions = { text: '', type: ListOptionType.WARN };

  /** List. */
  @Input()
  public list: T[] | null = [];

  /** Option types. */
  public readonly optionTypes = ListOptionType;

  /** Function which process element and show formatted value. */
  @Input()
  public getFormattedElement = (elem: T) => elem.toString()
}
