import { UserType } from '../enums/user-type';

/** List with all route paths */
export const routePaths = {
  home: ['/'],

  // Auth Module
  login: ['/auth/login'],
  resetPassword: ['/auth/reset-password'],

  // Users Module
  users: ['/users'],
  newUser: ['/users/new'],
  editUser: (userId: string | number) => ([...routePaths.users, 'edit', userId]),

  // Classes Module
  classes: ['/classes'],
  newClass: ['/classes/new'],
  overviewClass: (id: string | number) => ([...routePaths.classes, id, 'overview']),
  editClass: (id: string | number) => ([...routePaths.classes, id, 'edit']),
  cloneClass: (id: string | number) => ([...routePaths.classes, id, 'clone']),

  // Studios Module
  studios: ['/studios'],
  editStudio: (studioId: string | number) => ([...routePaths.studios, 'edit', studioId]),

  // Scheduler Module
  scheduler: ['/scheduler'],

  // Instructors Module
  instructors: ['/instructors'],
  newInstructor: ['/instructors/new'],
  editInstructor: (id: string | number) => ([...routePaths.instructors, 'edit', id]),

  // Students Module
  students: ['/students'],
  newStudent: ['/students/new'],
  editStudent: (id: string | number) => ([...routePaths.students, 'edit', id]),
  studentPayment: (id: string | number) => ([...routePaths.students, 'payment', id]),
  studentParentPayment: (id: string | number) => ([...routePaths.students, 'parent-payment', id]),

  // Holidays module
  holidays: ['/holidays'],

  // Payment history module
  paymentHistory: ['/payment-history'],
  parentPayments: (id: string | number) => ([...routePaths.paymentHistory, id]),

  notFound: ['/not-found'],
};

/**
 * Main pages for each of user type
 * Here are specified pages with high priority, where the user will be redirected after login.
 */
export const mainPageByUserType: Record<UserType, string[]> = {
  [UserType.Admin]: routePaths.users,
  [UserType.StudioManager]: routePaths.classes,
  [UserType.Staff]: routePaths.classes,
  [UserType.Instructor]: routePaths.classes,
  [UserType.Student]: routePaths.classes,
};
