<div *ngIf="user$ | async as user" class="user-details" [matMenuTriggerFor]="menu">
  <div class="column">
    <span class="user-icon">
      <span class="default-icon">{{ user.firstName[0] }} {{ user.lastName[0] }}</span>
    </span>
    <div class="credits" *ngLet="displayedName$|async as displayedName">
      <div class="email">{{ user.email }}</div>
      <div class="name">{{ displayedName }}</div>
    </div>
  </div>
  <mat-icon>arrow_drop_down</mat-icon>
</div>

<mat-menu class="user-menu" #menu="matMenu" [overlapTrigger]="false">
  <button mat-menu-item (click)="logout()">
    <mat-icon>power_settings_new</mat-icon>
    <span>Logout</span>
  </button>
</mat-menu>
