import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

/**
 * Service for work with custom svg icons.
 */
@Injectable({
  providedIn: 'root',
})
export class IconsService {
  constructor(
    private readonly iconRegistry: MatIconRegistry,
    private readonly sanitizer: DomSanitizer,
  ) {
  }

  /**
   * Register all icons.
   */
  public registerIcons(): void {
    this.iconRegistry.addSvgIcon(
      `cancelled`,
      this.sanitizer.bypassSecurityTrustResourceUrl('./assets/icons/check-canceled.svg'),
    );
    this.iconRegistry.addSvgIcon(
      `success`,
      this.sanitizer.bypassSecurityTrustResourceUrl('./assets/icons/check-success.svg'),
    );
    this.iconRegistry.addSvgIcon(
      `error`,
      this.sanitizer.bypassSecurityTrustResourceUrl('./assets/icons/error-icon.svg'),
    );
    this.iconRegistry.addSvgIcon(
      `export-csv`,
      this.sanitizer.bypassSecurityTrustResourceUrl('./assets/icons/export.svg'),
    );
  }
}
