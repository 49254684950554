import { Injectable } from '@angular/core';

import { UserStatus } from '../../enums/user-status';
import { User } from '../../models/user';

import { UserDto, UserEditDto } from './dto/user-dto';
import { IMapperFromDto, IMapperToDto } from './mappers';

/**
 * Mapper for User.
 * Use it only for other mappers.
 */
@Injectable({ providedIn: 'root' })
export class UserMapper implements
  IMapperFromDto<UserDto, User>,
  IMapperToDto<UserEditDto, User> {
  /** @inheritdoc */
  public fromDto(data: UserDto): User {
    return new User({
      id: data.id ?? 0,
      firstName: data.first_name,
      lastName: data.last_name,
      email: data.email,
      phone: data.phone,
      status: data.is_active ? UserStatus.Active : UserStatus.Inactive,
      created: new Date(data.created ?? ''),
      modified: new Date(data.modified ?? ''),
    });
  }

  /** @inheritdoc */
  public toDto(data: User): UserEditDto {
    return {
      first_name: data.firstName,
      last_name: data.lastName,
      email: data.email,
      phone: data.phone,
      is_active: data.status === UserStatus.Active ? true : false,
    };
  }
}
