<mat-form-field *ngIf="formControl">
  <mat-label *ngIf="label">{{ label }}</mat-label>
  <gymautoc-loader *ngIf="loading" class="loader" [diameter]="30"></gymautoc-loader>
  <mat-select
    class="select"
    [required]="required"
    [formControl]="formControl"
    [placeholder]="placeholder"
    (openedChange)="onOpenChange.emit($event)"
  >
    <mat-option>
      <ngx-mat-select-search
        gymautocSelectSearch
        [formControl]="searchControl"
      ></ngx-mat-select-search>
    </mat-option>
    <mat-progress-bar
      *ngIf="searchLoading"
      class="progress-bar"
      mode="indeterminate"
    ></mat-progress-bar>
    <mat-option *ngIf="includeEmptyOption" [value]="null">Not selected</mat-option>
    <mat-option
      *ngFor="let instructor of instructorList; trackBy: trackByInstructorId"
      [value]="instructor.id"
    >
      {{ instructor.firstName }} {{ instructor.lastName }}
    </mat-option>
  </mat-select>
  <mat-error>
    <gymautoc-form-control-validation-message [control]="formControl">
    </gymautoc-form-control-validation-message>
  </mat-error>
</mat-form-field>
