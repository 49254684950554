import { UserType } from '../enums/user-type';

import { User } from './user';

/** User profile model. */
export class UserProfile extends User {
  /** User type. */
  public type: UserType;

  /** Info about user's studio */
  public studio: UserProfileStudioData | null;

  /**
   * @constructor
   */
  public constructor(data: ConstructorInitArg<UserProfile>) {
    super(data);
    this.type = data.type;
    this.studio = data.studio;
  }
}

/** Info about user's studio */
interface UserProfileStudioData {
  /** Studio Id */
  id: number;
  /** Studio name */
  name: string;
}
