<div class="table-container">
  <table mat-table [dataSource]="dataSource" class="table">
    <!-- Checkbox -->
    <ng-container matColumnDef="checkbox">
      <th mat-header-cell *matHeaderCellDef class="checkboxColumn">
        <mat-checkbox
          color="primary"
          [checked]="isAllElementsSelected$.value"
          (click)="toggleElementFromList()"
        >
        </mat-checkbox>
      </th>
      <td mat-cell class="checkboxColumn" *matCellDef="let row">
        <mat-checkbox
          color="primary"
          [checked]="isChecked(row)"
          (click)="toggleElementFromList(row)"
        >
        </mat-checkbox>
      </td>
    </ng-container>

    <!-- Content Column -->
    <ng-container matColumnDef="content">
      <th mat-header-cell *matHeaderCellDef class="contentColumn title">
        {{ contentColumnTitle }}
      </th>
      <td mat-cell class="contentColumn" *matCellDef="let row">
        {{ contentColumnTransform(row) }}
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</div>
<div *ngIf="showRequiredError && selectedElements$.value.length === 0" class="error">
  At least one element should be selected from list.
</div>
