/**
 * User Status.
 */
 export enum UserStatus {
  /** Active. */
  Active = 'Active',
  /** Inactive. */
  Inactive = 'Inactive',
}

export namespace UserStatus {
  const TITLE_MAP: Record<UserStatus, string> = {
    [UserStatus.Active]: 'Active',
    [UserStatus.Inactive]: 'Inactive',
  };

  /**
   * Convert a certain enum value to readable title.
   * @param value Value of enum.
   */
  // tslint:disable-next-line: completed-docs
  export function toReadable(value: UserStatus): string {
    const readable = TITLE_MAP[value];
    if (readable === undefined) {
      console.warn('Unknown UserStatus: ' + value);
    }
    return readable;
  }

  /**
   * Get list of all enum items.
   */
  // tslint:disable-next-line:completed-docs
  export function toArray(): UserStatus[] {
    const enumType = typeof UserStatus.Inactive;
    return Object.values(UserStatus)
      .filter(status => typeof status === enumType)
      .map(status => status as UserStatus);
  }

  /**
   * Track by function.
   */
  // tslint:disable-next-line:completed-docs
  export function trackBy(_index: number, item: UserStatus): UserStatus {
    return item;
  }
}
