<ul class="list">
  <li *ngFor="let elem of list" class="element-row">
    <div class="element">
      {{ getFormattedElement(elem) }}
      <span
        *ngIf="option?.text"
        class="option-label"
        [ngClass]="{
          warn: option.type === optionTypes.WARN,
          success: option.type === optionTypes.SUCCESS
        }"
        >{{ option.text }}</span
      >
    </div>
  </li>
</ul>
