import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { ProgressContainerComponent } from '@gymautoc/common/shared/components/progress-container/progress-container.component';
import {
  Observable,
  BehaviorSubject,
  throwError,
  MonoTypeOperatorFunction,
  pipe,
  defer,
  EMPTY,
  timer,
} from 'rxjs';
import {
  switchMap,
  mapTo,
  catchError,
  map,
  first,
  tap,
  filter,
  distinctUntilChanged,
  debounceTime,
  mergeMap,
  finalize,
} from 'rxjs/operators';

/**
 *  Class for loading identifiers
 */
export class LoadingIdentifier {
  /** Internal value of identifier */
  private readonly internalValue: Symbol;

  /**
   * @constructor
   * @param name name of loading instance (for debugging)
   */
  public constructor(name: string) {
    this.internalValue = Symbol(name);
  }

  /** Get value of identifier */
  public get value(): Symbol {
    return this.internalValue;
  }

  /** Get identifier description */
  public get description(): string {
    return this.internalValue.toString();
  }
}

/**
 * Service to show loading indicator.
 */
@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  /** Current instance of loader */
  private readonly overlayRef$ = new BehaviorSubject<OverlayRef | null>(null);

  /** Indicates that process of the loader's presenting is already initiated */
  private loadingInitialized = false;

  /** Current state of loading */
  private readonly loadingIds$ = new BehaviorSubject(new Set<Symbol | null>());

  /** Current public state of loading */
  public readonly isLoading$ = this.loadingIds$.pipe<boolean>(map(ids => ids.size > 0));

  /** Debounce time to prevent loader hiding and showing many times */
  private readonly debounceTime = 200;

  /** Log each loading for debug */
  private readonly debug = false;

  /**
   * @constructor
   */
  public constructor(private overlay: Overlay) {
    // It tracks `isLoading` changing to show and hide the loader
    this.isLoading$
      .pipe(
        distinctUntilChanged(),
        // Wait some time and hide spinner, if there aren't any other loading
        debounceTime(this.debounceTime),
        mergeMap(isLoading => defer(() => (isLoading ? this.showLoader() : this.hideLoader()))),
      )
      .subscribe();

    if (this.debug) {
      this.loadingIds$.subscribe(ids => {
        console.log('Loading:', [...ids.values()]);
      });
    }
  }

  /**
   * Initialize and show the loader
   */
  private showLoader(): Observable<void> {
    return this.overlayRef$.pipe(
      first(),
      filter(overlayRef => !overlayRef && !this.loadingInitialized),
      map(() => {
        this.loadingInitialized = true;
        const overlayRef = this.overlay.create({
          positionStrategy: this.overlay
            .position()
            .global()
            .centerHorizontally()
            .centerVertically(),
          hasBackdrop: true,
        });

        overlayRef.attach(new ComponentPortal(ProgressContainerComponent));

        this.overlayRef$.next(overlayRef);
      }),
    );
  }

  /**
   * Dismiss the loader
   */
  private hideLoader(): Observable<boolean> {
    return this.overlayRef$.pipe(
      first(),
      switchMap(overlayRef => {
        /*
         Because of race condition could appear, when hiding process actually take control we have to check
         that the current service state match condition of loader hiding
        */

        /*
         No need to hide loader, if
         - some processes is registered in loading queue;
         - loading process hasn't been initialized at all.
        */
        if (!this.loadingInitialized || this.loadingIds$.getValue().size > 0) {
          return EMPTY;
        }

        /*
         If loading process has been initialized but hasn't completed yet (loader isn't exist)
         it needs to wait for the initialization to complete and then try to hide loader again
        */
        if (this.loadingInitialized && !overlayRef) {
          return timer(this.debounceTime).pipe(switchMap(() => this.hideLoader()));
        }

        // In other cases hide loader
        if (overlayRef) {
          this.loadingInitialized = false;
          this.overlayRef$.next(null);
          overlayRef.detach();
          return EMPTY;
        }

        return EMPTY;
      }),
    );
  }

  /**
   * Generate Identifier for the loading
   * @param name name of loading instance (for debugging)
   */
  public generateId(name: string): LoadingIdentifier {
    return new LoadingIdentifier(name);
  }

  /**
   * Start loading
   * Add new loading action to loading queue.
   *
   * @param identifier uniquer identifier for this loading action.
   */
  public start(identifier: LoadingIdentifier): Observable<void> {
    const id = identifier.value;
    return this.loadingIds$.pipe(
      first(),
      tap(ids => {
        if (!ids.has(id)) {
          ids.add(id);
          this.loadingIds$.next(ids);
        }
      }),
      mapTo(undefined),
    );
  }

  /**
   * Finish loading
   * On loading action is completed remove it from loading queue
   *
   * @param identifier uniquer identifier for this loading action.
   */
  public finish(identifier: LoadingIdentifier): Observable<void> {
    const id = identifier.value;
    return this.loadingIds$.pipe(
      first(),
      tap(ids => {
        const deleted = ids.delete(id);
        if (!deleted) {
          return;
        }
        this.loadingIds$.next(ids);
      }),
      mapTo(undefined),
    );
  }

  /**
   * Finish loading in any cases
   * Example:
   *   const loadingId = 'some-id';
   *   this.loadingService.start$(loadingId).pipe(
   *     switchMap(() => apiCall()),
   *     this.loadingService.finishInAnyCases(loadingId),
   *   );
   */
  public finishInAnyCases<T>(identifier: LoadingIdentifier): MonoTypeOperatorFunction<T> {
    const finish$ = defer(() => this.finish(identifier));
    return pipe(
      switchMap(value => finish$.pipe(mapTo(value))),
      finalize(() => finish$.pipe(first()).subscribe()),
      catchError(e => finish$.pipe(switchMap(() => throwError(e)))),
    );
  }
}
