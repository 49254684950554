import { UserStatus } from '../enums/user-status';

/**
 * User base model.
 * Don't use this model directly. There are different models for different users.
 */
export class User {
  /** Id. */
  public id: number;

  /** First name */
  public firstName: string;

  /** Last name. */
  public lastName: string;

  /** Email. */
  public email?: string;

  /** Phone. */
  public phone?: string;

  /** Status. */
  public status: UserStatus;

  /** Creation date. */
  public created: Date;

  /** Last update date. */
  public modified: Date;

  /**
   * @constructor
   */
  public constructor(data: ConstructorInitArg<User>) {
    this.id = data.id;
    this.firstName = data.firstName;
    this.lastName = data.lastName;
    this.email = data.email;
    this.phone = data.phone;
    this.status = data.status;
    this.created = data.created;
    this.modified = data.modified;
  }

  /** Sort by full name. */
  public static sortByFullName(a: User, b: User): number {
    const aFullName = `${a.firstName} ${a.lastName}`;
    const bFullName = `${b.firstName} ${b.lastName}`;

    return aFullName.localeCompare(bFullName);
  }
}
