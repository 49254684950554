import { Pipe, PipeTransform } from '@angular/core';
import { getMomentDate } from '@gymautoc/common/core/utils/datetime-utils';

/**
 * Pipe for transforming date or time by moment format.
 */
@Pipe({
  name: 'momentDatetime',
})
export class MomentDatetimePipe implements PipeTransform {
  /**
   * Format date according to moment format.
   * @param date Date to format
   * @param format Format
   */
  public transform(date?: Date, format: string = 'YYYY-MM-DD'): string {
    return date ? getMomentDate(date).format(format) : '';
  }
}
