<ng-container *ngIf="breadcrumbs$ | async as breadcrumbs">
  <div
    *ngIf="breadcrumbs.length > 1"
    class="breadcrumbs">
    <ng-container *ngFor="let crumb of breadcrumbs; let first = first; let last = last; trackBy: trackByBreadcrumb">
      <div>{{ first ? '' : '/' }}</div>
      <ng-container *ngIf="!last">
        <div
          *ngIf="!crumb.terminal; else noLinkBlock"
          class="text-ellipsis">
          <a class="primary" [routerLink]="crumb.url">
            {{ crumb.displayName }}
          </a>
        </div>
        <ng-template #noLinkBlock>
          <div class="text-ellipsis">
            {{ crumb.displayName }}
          </div>
        </ng-template>
      </ng-container>
    </ng-container>
  </div>
</ng-container>
