/** List days of the week */
export enum WeekDays {
  /** Monday */
  Monday = 'MONDAY',

  /** Tuesday */
  Tuesday = 'TUESDAY',

  /** Wednesday */
  Wednesday = 'WEDNESDAY',

  /** Thursday */
  Thursday = 'THURSDAY',

  /** Friday */
  Friday = 'FRIDAY',

  /** Saturday */
  Saturday = 'SATURDAY',

  /** Sunday */
  Sunday = 'SUNDAY',
}

/** Add some methods to enum */
export namespace WeekDays {
  const TITLE_MAP_SHORT: Record<WeekDays, string> = {
    [WeekDays.Monday]: 'Mon',
    [WeekDays.Tuesday]: 'Tue',
    [WeekDays.Wednesday]: 'Wed',
    [WeekDays.Thursday]: 'Thu',
    [WeekDays.Friday]: 'Fri',
    [WeekDays.Saturday]: 'Sat',
    [WeekDays.Sunday]: 'Sun',
  };

  const TITLE_MAP_LONG: Record<WeekDays, string> = {
    [WeekDays.Monday]: 'Monday',
    [WeekDays.Tuesday]: 'Tuesday',
    [WeekDays.Wednesday]: 'Wednesday',
    [WeekDays.Thursday]: 'Thursday',
    [WeekDays.Friday]: 'Friday',
    [WeekDays.Saturday]: 'Saturday',
    [WeekDays.Sunday]: 'Sunday',
  };

  /**
   * Get list of all available priorities
   */
  // tslint:disable-next-line:completed-docs
  export function toArray(): WeekDays[] {
    return [
      WeekDays.Monday,
      WeekDays.Tuesday,
      WeekDays.Wednesday,
      WeekDays.Thursday,
      WeekDays.Friday,
      WeekDays.Saturday,
      WeekDays.Sunday,
    ];
  }

  /**
   * Convert a certain enum value to readable title.
   * @param value Value of enum.
   */
  // tslint:disable-next-line: completed-docs
  export function toReadable(value: WeekDays): string {
    const readable = TITLE_MAP_SHORT[value];
    if (readable === undefined) {
      console.warn('Unknown WeekDay: ' + value);
    }
    return readable;
  }

  /**
   * Convert a certain enum value to readable title.
   * @param value Value of enum.
   */
  // tslint:disable-next-line: completed-docs
  export function toReadableLong(value: WeekDays): string {
    const readable = TITLE_MAP_LONG[value];
    if (readable === undefined) {
      console.warn('Unknown WeekDay: ' + value);
    }
    return readable;
  }

  /**
   * Get number value of week day (Monday is 1, Sunday is 7).
   */
  // tslint:disable-next-line:completed-docs
  export function toNumber(day: WeekDays): number {
    return toIndex(day) + 1;
  }

  /**
   * Get index of week day (Monday is 0, Sunday is 6).
   */
  // tslint:disable-next-line:completed-docs
  export function toIndex(day: WeekDays): number {
    return WeekDays.toArray().indexOf(day);
  }

  /**
   * Get weekday name by its index in array.
   * @param index Index of week day.
   * @param returnShortName Flag for display weekday name as short (f.e Mon/Wed)
   */
  // tslint:disable-next-line:completed-docs
  export function getWeekdayNameByIndex(index: number, returnShortName: boolean = false): string {
    const weekday = WeekDays.toArray()[index];
    return returnShortName ? WeekDays.toReadable(weekday) : WeekDays.toReadableLong(weekday);
  }

  /**
   * Track by function
   */
  // tslint:disable-next-line:completed-docs
  export function trackBy(_index: number, item: WeekDays): WeekDays {
    return item;
  }
}
