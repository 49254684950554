import { Injectable } from '@angular/core';

import { EntityValidationErrors } from '../../models/app-error';
import { StudioUser } from '../../models/studio-user';
import { UserEditForm } from '../user-edit-page.service';

import { StudioUserDto, StudioUserEditDto } from './dto/studio-user-dto';
import { extractErrorMessage, ValidationErrorDto } from './dto/validation-error-dto';
import { IMapperFromDto, IMapperToDto, IValidationErrorMapper } from './mappers';
import { UserMapper } from './user-mapper';

/**
 * Mapper for StudioUser.
 */
@Injectable({ providedIn: 'root' })
export class StudioUserMapper implements
  IMapperFromDto<StudioUserDto, StudioUser>,
  IMapperToDto<StudioUserEditDto, StudioUser>,
  IValidationErrorMapper<StudioUserEditDto, UserEditForm> {
  /**
   * @constructor
   */
  public constructor(
    private readonly userMapper: UserMapper,
  ) {
  }

  /** @inheritdoc */
  public fromDto(data: StudioUserDto): StudioUser {
    return new StudioUser({
      ...this.userMapper.fromDto(data),
      role: data.role,
      studio: data.studio_data,
    });
  }

  /** @inheritdoc */
  public toDto(data: StudioUser): StudioUserEditDto {
    return {
      ...this.userMapper.toDto(data),
      role: data.role,
      studio: data.studio.id,
    };
  }

  /** @inheritdoc */
  public validationErrorFromDto(errorDto: ValidationErrorDto<StudioUserEditDto>): EntityValidationErrors<UserEditForm> {
    return {
      studio: extractErrorMessage(errorDto.studio),
      permission: extractErrorMessage(errorDto.role),
      firstName: extractErrorMessage(errorDto.first_name),
      lastName: extractErrorMessage(errorDto.last_name),
      email: extractErrorMessage(errorDto.email),
      phone: extractErrorMessage(errorDto.phone),
    };
  }
}
