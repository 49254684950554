/**
 * User types.
 * Note: UserType is not the same thing as UserRole.
 * UserType is for user permissions in app.
 * UserRole is for user role in studio.
 */
 export enum UserType {
  /** Admin. */
  Admin = 'ADMIN',
  /** Studio Manager. */
  StudioManager = 'MANAGER',
  /** Staff. */
  Staff = 'STAFF',
  /** Student. */
  Student = 'STUDENT',
  /** Student. */
  Instructor = 'INSTRUCTOR',
}

export namespace UserType {
  const TITLE_MAP: Record<UserType, string> = {
    [UserType.Admin]: 'Admin',
    [UserType.StudioManager]: 'Manager',
    [UserType.Staff]: 'Staff',
    [UserType.Instructor]: 'Instructor',
    [UserType.Student]: 'Student',
  };

  /**
   * Convert a certain enum value to readable title.
   * @param value Value of enum.
   */
  // tslint:disable-next-line: completed-docs
  export function toReadable(value: UserType): string {
    const readable = TITLE_MAP[value];
    if (readable === undefined) {
      console.warn('Unknown UserType: ' + value);
    }
    return readable;
  }

  /**
   * Get list of all enum items.
   */
  // tslint:disable-next-line:completed-docs
  export function toArray(): UserType[] {
    const enumType = typeof UserType.Admin;
    return Object.values(UserType)
      .filter(status => typeof status === enumType)
      .map(status => status as UserType);
  }

  /**
   * Track by function.
   */
  // tslint:disable-next-line:completed-docs
  export function trackBy(_index: number, item: UserType): UserType {
    return item;
  }
}
