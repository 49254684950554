import { Injectable } from '@angular/core';

import { EntityValidationErrors } from '../../models/app-error';
import { NewPasswordRequestData } from '../auth.service';

import { NewPasswordDto } from './dto/new-password-dto';
import {
  extractErrorMessage,
  ValidationErrorDto,
} from './dto/validation-error-dto';
import { IMapperToDto } from './mappers';
import { ValidationErrorMapper } from './validation-error-mapper';

/**
 * Mapper for reset password confirmation.
 */
@Injectable({ providedIn: 'root' })
export class NewPasswordMapper implements
  IMapperToDto<NewPasswordDto, NewPasswordRequestData>,
  ValidationErrorMapper<NewPasswordDto, NewPasswordRequestData> {

  /** @inheritdoc */
  public validationErrorFromDto(errorDto: ValidationErrorDto<NewPasswordDto>): EntityValidationErrors<NewPasswordRequestData> {
    const validationErrors: EntityValidationErrors<NewPasswordRequestData> = {
      password: extractErrorMessage(errorDto.password),
      confirmPassword: extractErrorMessage(errorDto.password_confirm),
      uid: extractErrorMessage(errorDto.uid),
      token: extractErrorMessage(errorDto.token),
    };
    return validationErrors;
  }

  /** @inheritdoc */
  public toDto(data: NewPasswordRequestData): NewPasswordDto {
    return {
      password: data.password,
      password_confirm: data.confirmPassword,
      uid: data.uid,
      token: data.token,
    };
  }
}
