import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBar } from '@angular/material/snack-bar';
import { NotificationOptions } from '@gymautoc/common/core/models/notification-options';

/** Custom notification component */
@Component({
  selector: 'gymautoc-notification-message',
  templateUrl: './notification-message.component.html',
  styleUrls: ['./notification-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationMessageComponent {

  /**
   * @constructor
   * @param options notification options
   * @param matSnackBarService material snack bar service
   */
  public constructor(
    @Inject(MAT_SNACK_BAR_DATA) public options: NotificationOptions,
    private readonly matSnackBarService: MatSnackBar,
  ) {
  }

  /** Is warning message */
  public get isWarn(): boolean {
    return this.options.type === 'warn';
  }

  /** Close the snackbar */
  public closeSnackbar(): void {
    this.matSnackBarService.dismiss();
  }
}
