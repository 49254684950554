import { Injectable } from '@angular/core';

import { EntityValidationErrors } from '../../models/app-error';
import { LoginData } from '../auth.service';

import { LoginDto } from './dto/auth-dto';
import { extractErrorMessage, ValidationErrorDto } from './dto/validation-error-dto';
import { IMapperToDto } from './mappers';
import { ValidationErrorMapper } from './validation-error-mapper';

/**
 * Mapper for login entities.
 */
@Injectable({ providedIn: 'root' })
export class LoginMapper implements
  IMapperToDto<LoginDto, LoginData>,
  ValidationErrorMapper<LoginDto, LoginData> {

  /** @inheritdoc */
  public validationErrorFromDto(errorDto: ValidationErrorDto<LoginDto>): EntityValidationErrors<LoginData> {
    const validationErrors: EntityValidationErrors<LoginData> = {
      email: extractErrorMessage(errorDto.email),
      password: extractErrorMessage(errorDto.password),
    };
    return validationErrors;
  }

  /** @inheritdoc */
  public toDto(data: LoginData): LoginDto {
    const dto: LoginDto = {
      email: data.email,
      password: data.password,
    };
    return dto;
  }
}
