import { Component, ChangeDetectionStrategy, Input, ViewEncapsulation } from '@angular/core';
import {
  onMainContentChange,
  onSidebarChange,
  SIDEBAR_ANIMATION_DELAY,
} from '@gymautoc/common/core/animations/sidebar-animations';
import { NavigationLink } from '@gymautoc/common/core/models/navigation-link';
import { ToggleSubject } from '@gymautoc/common/core/rxjs/toggle-subject';
import { of } from 'rxjs';
import { delay, switchMap } from 'rxjs/operators';

/**
 * Sidebar component.
 * This component has default layout which can be customized.
 * Use customSidebar and sidebar-content for sidebar customization.
 * Use customPage and page-content for page customization.
 */
@Component({
  selector: 'gymautow-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  animations: [onSidebarChange, onMainContentChange],
})
export class SidebarComponent {
  /** Sidebar links. */
  @Input()
  public links?: NavigationLink[] | null;

  /** Use custom layout for sidebar. Use 'sidebar-content' for content projection. */
  @Input()
  public customSidebar = false;

  /** Use custom layout for page. Use 'page-content' for content projection. */
  @Input()
  public customPage = false;

  /** Name of user studio. */
  @Input()
  public studioName: string | null = null;

  /** Current sidenav state. */
  public readonly sidebarOpened$ = new ToggleSubject(true);

  /** Set delay for sidenav content. */
  public readonly showSidebarContent$ = this.sidebarOpened$.pipe(
    switchMap(isOpen => {
      if (isOpen) {
        return of(isOpen).pipe(delay(SIDEBAR_ANIMATION_DELAY));
      } else {
        return of(isOpen);
      }
    }),
  );

  /** Toggle sidenav state. */
  public onSidenavToggle(): void {
    this.sidebarOpened$.toggle();
  }

}
