import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  OnInit,
} from '@angular/core';
import {
  MAT_CHECKBOX_DEFAULT_OPTIONS,
  MatCheckboxDefaultOptions,
} from '@angular/material/checkbox';
import { MatTableDataSource } from '@angular/material/table';
import { ToggleSubject } from '@gymautoc/common/core/rxjs/toggle-subject';
import { BehaviorSubject } from 'rxjs';

/**
 * List with possibility of selection elements.
 */
@Component({
  selector: 'gymautoc-selectable-list',
  templateUrl: './selectable-list.component.html',
  styleUrls: ['./selectable-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: MAT_CHECKBOX_DEFAULT_OPTIONS,
      useValue: { clickAction: 'noop' } as MatCheckboxDefaultOptions,
    },
  ],
})
export class SelectableListComponent<T extends {}> implements OnInit {
  /** Title for content column. */
  @Input()
  public contentColumnTitle = '';

  /** Should show error about required. */
  @Input()
  public showRequiredError = false;

  /** Emitter for select elements. */
  @Output()
  public readonly passSelectedElements: EventEmitter<T[]> = new EventEmitter();

  /** Emitter for deleted elements. */
  @Output()
  public readonly passDeletedElements: EventEmitter<T[]> = new EventEmitter();

  /** Columns for table. */
  public readonly displayedColumns = ['checkbox', 'content'];

  /** Internal representation of data source for support of native sorting feature */
  public readonly dataSource = new MatTableDataSource<T>([]);

  private readonly allListElements$ = new BehaviorSubject<readonly T[]>([]);

  private readonly alreadySelectedElements$ = new BehaviorSubject<T[]>([]);

  /** Selected elements from list. */
  public readonly selectedElements$ = new BehaviorSubject<T[]>([]);

  /** Deleted elements (which was selected from server). */
  public readonly deletedElements$ = new BehaviorSubject<T[]>([]);

  /** Flag about checkbox for all elements are selected. */
  public readonly isAllElementsSelected$ = new ToggleSubject(false);

  /** Function which check elements to be equal. */
  @Input()
  public checkElementsAreEqual = (elem1: T, elem2: T) => elem1.toString() === elem2.toString();

  /** Function which process element and show formatted value. */
  @Input()
  public contentColumnTransform = (elem: T) => elem.toString();

  /** Selected elements from server. */
  @Input()
  public alreadySelectedElements: T[] | null = [];

  /** Row for table - schedule days. */
  @Input()
  public set rows(value: readonly T[] | null) {
    if (value) {
      this.allListElements$.next(value);
      this.dataSource.data = [...value];
    }
  }

  /** @inheritDoc */
  public ngOnInit(): void {
    this.deletedElements$.next([]);
    this.isAllElementsSelected$.next(false);
    this.selectedElements$.next([]);

    if (this.alreadySelectedElements) {
      this.alreadySelectedElements$.next(this.alreadySelectedElements);
      this.alreadySelectedElements.forEach(elem => this.toggleElementFromList(elem));
    }
  }

  private isElemFromAlreadySelected(elem: T): boolean {
    const unselectServerElem = this.alreadySelectedElements$.value.filter(selectedElem =>
      this.checkElementsAreEqual(selectedElem, elem),
    );
    return unselectServerElem.length > 0;
  }

  /** Handler for select element at list. */
  public toggleElementFromList(elem?: T): void {
    const selectedElems = this.selectedElements$.value;
    const allElems = this.allListElements$.value;
    let nextValue: T[] = [];
    if (elem) {
      if (this.isChecked(elem)) {
        // Delete value from selected list. In case elem is from already selected (server) list - add it to deleted list.
        this.isAllElementsSelected$.next(false);
        if (this.isElemFromAlreadySelected(elem)) {
          this.deletedElements$.value.push(elem);
        }
        nextValue = selectedElems.filter(
          selectedDay => !this.checkElementsAreEqual(selectedDay, elem),
        );
      } else {
        // Add new selected value to selected list. In case elem is from already selected (server) list - delete it from deleted list.
        if (this.isElemFromAlreadySelected(elem)) {
          const newDeletedList = this.deletedElements$.value.filter(
            selectedDay => !this.checkElementsAreEqual(selectedDay, elem),
          );
          this.deletedElements$.next(newDeletedList);
        }
        nextValue = selectedElems.concat(elem);
      }
    } else {
      // If elem is empty - we should select all values.
      this.isAllElementsSelected$.toggle();
      nextValue = this.isAllElementsSelected$.value ? allElems.slice() : [];
      this.deletedElements$.next([]);
    }
    if (nextValue.length === allElems.length) {
      this.isAllElementsSelected$.next(true);
    }
    this.selectedElements$.next(nextValue);
    this.passSelectedElements.emit(nextValue);
    this.passDeletedElements.emit(this.deletedElements$.value);
  }

  /** Is checkbox checked. */
  public isChecked(elem: T): boolean {
    return Boolean(
      this.selectedElements$.value.find(selectedDay =>
        this.checkElementsAreEqual(selectedDay, elem),
      ),
    );
  }
}
