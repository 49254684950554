/** List Option type. */
export enum ListOptionType {
  /** Success */
  SUCCESS = 'success',
  /** Warn. */
  WARN = 'warn',
}

/** Options for notification */
export interface ListOptions {
  /** Text */
  text: string;

  /** Type */
  type: ListOptionType;
}
