import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { FullCalendarModule } from '@fullcalendar/angular';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeListPlugin from '@fullcalendar/list';
import timeGridPlugin from '@fullcalendar/timegrid';
import '@gymautoc/common/core/utils/constructor-init-arg';
import { NgxMaskModule } from 'ngx-mask';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { InfiniteScrollModule } from "ngx-infinite-scroll";

import { AppCardComponent } from './components/app-card/app-card.component';
import { AppDialogComponent } from './components/app-dialog/app-dialog.component';
import { InstructorSelectComponent } from './components/instructor-select/instructor-select.component';
import { DatePickerComponent } from './components/date-picker/date-picker.component';
import { FormControlValidationMessageComponent } from './components/form-control-validation-message/form-control-validation-message.component';
import { ListComponent } from './components/list/list.component';
import { LoaderComponent } from './components/loader/loader.component';
import { NotificationMessageComponent } from './components/notification-message/notification-message.component';
import { PageLoaderComponent } from './components/page-loader/page-loader.component';
import { PortalWindowComponent } from './components/portal-window/portal-window.component';
import { PrintableContainerComponent } from './components/printable-container/printable-container.component';
import { ProgressContainerComponent } from './components/progress-container/progress-container.component';
import { SelectableListComponent } from './components/selectable-list/selectable-list.component';
import { ValidationMessageComponent } from './components/validation-message/validation-message.component';
import { AppValidationDirective } from './directives/app-validation.directive';
import { ConnectedOverlayDirective } from './directives/connected-overlay.directive';
import { CustomSelectSearchDirective } from './directives/custom-select-search.directive';
import { NgLetDirective } from './directives/ng-let';
import { RestrictUserTypesDirective } from './directives/restrict-user-types.directive';
import { MaterialModule } from './material.module';
import { BreadcrumbsModule } from './modules/breadcrumbs/breadcrumbs.module';
import { MomentDatetimePipe } from './pipes/moment-datetime.pipe';

FullCalendarModule.registerPlugins([ // Register FullCalendar plugins
  dayGridPlugin,
  timeGridPlugin,
  timeListPlugin,
]);

const EXPORTED_DECLARATIONS = [
  // Directives.
  AppValidationDirective,
  NgLetDirective,
  ConnectedOverlayDirective,
  CustomSelectSearchDirective,
  RestrictUserTypesDirective,
  // Components.
  ValidationMessageComponent,
  FormControlValidationMessageComponent,
  AppCardComponent,
  PageLoaderComponent,
  LoaderComponent,
  AppDialogComponent,
  SelectableListComponent,
  MomentDatetimePipe,
  ListComponent,
  DatePickerComponent,
  PrintableContainerComponent,
  InstructorSelectComponent,
];

const EXPORTED_MODULES = [
  MaterialModule,
  BreadcrumbsModule,
  NgxMaskModule,
  FullCalendarModule,
  NgxMatSelectSearchModule,
  PortalModule,
  InfiniteScrollModule,
];

const DECLARATIONS = [
  ProgressContainerComponent,
  NotificationMessageComponent,
  PortalWindowComponent,
];

/**
 * Common shared module.
 * Contains reusable components, directives and etc can be shared through all apps in the project.
 */
@NgModule({
  declarations: [
    ...DECLARATIONS,
    ...EXPORTED_DECLARATIONS,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ...EXPORTED_MODULES,
  ],
  exports: [
    ...EXPORTED_DECLARATIONS,
    ...EXPORTED_MODULES,
  ],
})
export class CommonSharedModule { }
