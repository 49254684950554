import { ChangeDetectorRef, Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { UserType } from '@gymautoc/common/core/enums/user-type';
import { CurrentUserService } from '@gymautoc/common/core/services/current-user.service';
import { DestroyableComponent, takeUntilDestroy } from '@gymautoc/common/core/utils/destroyable';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';

import { ConditionalRenderingDirective } from './conditional-rendering.directive';

/**
 * Directive which allow to show element only for specific user type
 */
@Directive({
  selector: '[gymautocRestrictUserTypes]',
})
@DestroyableComponent()
export class RestrictUserTypesDirective extends ConditionalRenderingDirective {
  /** Roles */
  @Input('gymautocRestrictUserTypes')
  public set roles(types: UserType[]) {
    this.roles$.next(types);
  }

  private readonly roles$ = new BehaviorSubject<UserType[]>([]);

  /** @constructor */
  public constructor(
    private readonly currentUserService: CurrentUserService,
    protected readonly viewContainer: ViewContainerRef,
    protected readonly changeDetector: ChangeDetectorRef,
    protected readonly templateRef: TemplateRef<any>,
  ) {
    super(viewContainer, changeDetector, templateRef);

    const userRole$ = this.currentUserService.currentUser$.pipe(
      map(u => u?.type),
      distinctUntilChanged(),
    );

    combineLatest([
      userRole$,
      this.roles$,
    ]).pipe(
      takeUntilDestroy(this),
    ).subscribe(([userType, types]) => {
      if (userType && types.includes(userType)) {
        this.renderContent();
      } else {
        this.clearContent();
      }
    });
  }

}
