import * as moment from 'moment';

import { WeekDays } from '../enums/week-days';
import { ClassEvent } from '../models/class-event';
import { ScheduleInfo } from '../models/schedule-info';

/** Date format to display on page. */
export const DATE_DISPLAY_FORMAT = 'MM/DD/YYYY';

/** Number for parse date. */
const MILLISECONDS_PER_MINUTE = 60000;
/**
 * Get moment.js date object.
 * Returns current time if no input is provided.
 * @param date Input to parse.
 * @returns Moment object.
 */
export function getMomentDate(date?: moment.MomentInput, format?: moment.MomentFormatSpecification): moment.Moment {
  return moment(date, format);
}

/**
 * Set time for date.
 * @param date Date.
 * @param time Time.
 * @returns New Date.
 */
export function setTimeForDate(date: Date, time: Date): Date {
    return getMomentDate(date).set({
    hours: time.getHours(),
    minutes: time.getMinutes(),
    seconds: time.getSeconds(),
  }).toDate();
}

/**
 * Get ISO date string from date.
 * The output format will be YYYY-MM-DD.
 * @param date Date.
 * @param format Format.
 * @returns Date string.
 */
export function getDateString(date: moment.MomentInput, format: string = 'YYYY-MM-DD'): string {
  return getMomentDate(date).format(format);
}

/**
 * Get time string from date.
 * The output format will be HH:mm:ss.
 * Uses 24-hours format.
 * @param date Date.
 * @returns Time string.
 */
export function getFullTimeString(date: moment.MomentInput): string {
  return getMomentDate(date).format('HH:mm:ss');
}

/**
 * Get short time string from date.
 * The output format will be hh:mm (05:20) or hh:mma (05:20pm).
 * Uses 12-hours format.
 * @param date Date.
 * @param showPeriod Show am/pm in output.
 * @returns Time string.
 */
export function getShortTimeString(date?: moment.MomentInput | null, showPeriod: boolean = false): string {
  const format = showPeriod ? 'hh:mma' : 'hh:mm';
  return getMomentDate(date).format(format);
}

/**
 * Transfer string with time to Date object.
 * @param time Time in string format.
 */
export function parseTimeString(time: string): Date {
  return getMomentDate(time, 'HH:mm:ss').toDate();
}

/**
 * Parse input from input with type='time'.
 * @param time Input time.
 */
export function parseTimeInput(time: string): Date {
  return getMomentDate(time, 'HH:mm').toDate();
}

/**
 * Get formatted string for input with type='time'.
 * @param time Input time.
 */
export function formatDateForTimeInput(time?: Date | null): string {
  return time ? getMomentDate(time).format('HH:mm') : '';
}

/**
 * Transfer string with datetime to Date object.
 * @param date Time in string format.
 */
export function parseDate(date?: moment.MomentInput): Date {
  // If we don't pass date arg to this method - we return current date.
  return date ? getMomentDate(date).toDate() : getMomentDate().toDate();
}

/**
 * Transfer string with datetime to Date object without time zone.
 * If we don't pass date arg to this method - we return current date.
 * @param date Time in string format.
 */
 export function parseDateWithoutTZ(date?: moment.MomentInput): Date {
  const localDate = date ? getMomentDate(date).toDate() : getMomentDate().toDate();
  const userTimezoneOffset = localDate.getTimezoneOffset() * MILLISECONDS_PER_MINUTE;
  return new Date(localDate.getTime() + userTimezoneOffset);
}

/**
 * Compare time.
 * @param a First value.
 * @param b Second value.
 * @returns true if first value is less than the second.
 */
export function compareTime(a: Date | string, b: Date | string): boolean {
  return getMomentDate(a, 'HH:mm') < getMomentDate(b, 'HH:mm');
}

/**
 * Compare dates.
 * @param a First value.
 * @param b Second value.
 * @returns true if first value is less than the second.
 */
export function compareDates(a: Date | string, b: Date | string): boolean {
  return getMomentDate(a) < getMomentDate(b);
}

/**
 * Get formatted time range (as "Monday | 8:00 - 9:30 am").
 * @param scheduleDay Schedule day data
 */
export function getFormattedTimeRange(scheduleDay: ScheduleInfo): string {
  const weekday = WeekDays.toReadableLong(scheduleDay.weekday);
  const startTime = getShortTimeString(scheduleDay.startDateTime);
  const endDate = getShortTimeString(scheduleDay.endDateTime, true);
  return `${weekday} | ${startTime} - ${endDate}`;
}

/**
 * Get formatted time range (as "Wed, Aug 18, 2021 | 8:00 - 9:30am").
 * @param classEvent Class event data.
 */
export function getFormattedFullDayRange(classEvent: ClassEvent): string {
  // We need to use isoWeekday as usual weekday return different numbers according to locals (Sunday may be as 6 or 0)
  const weekday = WeekDays.getWeekdayNameByIndex(getMomentDate(classEvent.date).isoWeekday() - 1, true);
  const day = getMomentDate(classEvent.date).format('MMM DD, YYYY');
  const startTime = getShortTimeString(classEvent.startDatetime);
  const endDate = getShortTimeString(classEvent.endDatetime, true);
  return `${weekday}, ${day} | ${startTime} - ${endDate}`;
}

/**
 * Add days to passed date.
 * @param date Date.
 * @param daysToAdd Number of days to add.
 * @returns New date.
 */
export function addDaysToDate(date: Date, daysToAdd: number): Date {
  return getMomentDate(date).add(daysToAdd, 'days').toDate();
}
