import { Component } from '@angular/core';
import { IconsService } from '@gymautoc/common/core/services/icons.services';

/** Base app component. */
@Component({
  selector: 'gymautow-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  constructor(
    private readonly iconsService: IconsService,
  ) {
    this.iconsService.registerIcons();
  }
}
