import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CommonSharedModule } from '@gymautoc/common/shared/common-shared.module';

import { SidebarComponent } from './components/sidebar/sidebar.component';
import { UserInfoComponent } from './components/user-info/user-info.component';
import { LayoutComponent } from './layout.component';

/**
 * Layouts module.
 */
@NgModule({
  declarations: [
    LayoutComponent,
    SidebarComponent,
    UserInfoComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    CommonSharedModule,
    ReactiveFormsModule,
  ],
  exports: [
    LayoutComponent,
  ],
})
export class LayoutsModule { }
