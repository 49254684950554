import { CdkPortal, DomPortalOutlet } from '@angular/cdk/portal';
import {
  Component,
  ChangeDetectionStrategy,
  ViewChild,
  ApplicationRef,
  ComponentFactoryResolver,
  Injector, AfterViewInit,
  OnDestroy,
  EventEmitter,
  Output,
} from '@angular/core';

/**
 * Portal window component.
 */
@Component({
  selector: 'gymautoc-portal-window',
  templateUrl: './portal-window.component.html',
  styleUrls: ['./portal-window.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PortalWindowComponent implements AfterViewInit, OnDestroy {
  // STEP 1: Get a reference to the portal.
  /** CDK Portal reference. */
  @ViewChild(CdkPortal) public readonly portal!: CdkPortal;

  // STEP 2: Save a reference to the window so we can close it.
  private externalWindow: Window | null = null;

  /** Window reference. */
  @Output()
  public readonly windowReady = new EventEmitter<Window>();

  // STEP 3: Inject all the required dependencies for a PortalHost.
  public constructor(
    private readonly componentFactoryResolver: ComponentFactoryResolver,
    private readonly applicationRef: ApplicationRef,
    private readonly injector: Injector,
  ) { }

  /** @inheritdoc */
  public ngAfterViewInit(): void {
    // STEP 4: Create an external window.
    this.externalWindow = window.open();
    if (this.externalWindow) {
      this.externalWindow.document.title = window.document.title;

      // STEP 5: Create a PortalHost with the body of the new window document.
      const host = new DomPortalOutlet(
        this.externalWindow.document.body,
        this.componentFactoryResolver,
        this.applicationRef,
        this.injector,
      );

      // STEP 6: Copy all styles from parent window.
      this.importStylesFromParentWindow(this.externalWindow);

      // STEP 7: Attach the portal.
      host.attach(this.portal);

      // STEP 8: Emit window reference when it's ready.
      this.windowReady.emit(this.externalWindow);
    }
  }

  /** @inheritdoc */
  public ngOnDestroy(): void {
    this.externalWindow?.close();
    this.externalWindow = null;
  }

  private importStylesFromParentWindow(windowRef: Window): void {
    document.querySelectorAll('style').forEach(htmlElement => {
      this.externalWindow?.document.head.appendChild(htmlElement.cloneNode(true));
    });

    document.querySelectorAll('link').forEach(htmlElement => {
      if (htmlElement.rel) {
        const styleSheetElement = document.createElement('link');
        const absoluteUrl = new URL(htmlElement.href).href;
        styleSheetElement.rel = htmlElement.rel;
        styleSheetElement.href = absoluteUrl;
        windowRef.document.head.appendChild(styleSheetElement);
      }
    });
  }
}
