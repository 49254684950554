<mat-card>
  <div
    class="card-header"
    [class.primary]="color === 'primary'"
    [class.accent]="color === 'accent'">
    <ng-content select="[card-header]"></ng-content>
  </div>
  <mat-card-content [class.no-padding]="!padding">
    <ng-content></ng-content>
  </mat-card-content>
</mat-card>