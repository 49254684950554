import {
  trigger,
  state,
  style,
  transition,
  animate,
} from '@angular/animations';

export const SIDEBAR_WIDTH = 350;
export const SIDEBAR_MINI_WIDTH = 93;
export const SIDEBAR_ANIMATION_DELAY = 250;

export const onSidebarChange = trigger('onSidebarChange', [
  state(
    'close',
    style({
      'max-width': `${SIDEBAR_MINI_WIDTH}px`,
    }),
  ),
  state(
    'open',
    style({
      'max-width': `${SIDEBAR_WIDTH}px`,
    }),
  ),
  transition('close => open', animate(`${SIDEBAR_ANIMATION_DELAY}ms ease-in`)),
  transition('open => close', animate(`${SIDEBAR_ANIMATION_DELAY}ms ease-in`)),
]);

export const onMainContentChange = trigger('onMainContentChange', [
  state(
    'close',
    style({
      'margin-left': `${SIDEBAR_MINI_WIDTH}px`,
    }),
  ),
  state(
    'open',
    style({
      'margin-left': `${SIDEBAR_WIDTH }px`,
    }),
  ),
  transition('close => open', animate(`${SIDEBAR_ANIMATION_DELAY}ms ease-in`)),
  transition('open => close', animate(`${SIDEBAR_ANIMATION_DELAY}ms ease-in`)),
]);
