import { Injectable } from '@angular/core';

import { UserProfile } from '../../models/profile';

import { UserProfileDto } from './dto/user-profile-dto';
import { IMapperFromDto } from './mappers';
import { UserMapper } from './user-mapper';

/**
 * Mapper for UserProfile.
 */
@Injectable({ providedIn: 'root' })
export class UserProfileMapper implements IMapperFromDto<UserProfileDto, UserProfile> {
  /**
   * @constructor
   */
  public constructor(
    private readonly userMapper: UserMapper,
  ) {
  }

  /** @inheritdoc */
  public fromDto(data: UserProfileDto): UserProfile {
    return new UserProfile({
      ...this.userMapper.fromDto(data),
      type: data.user_type,
      // TODO (Anton T.): Maybe in the future, we will need a mapper for this data.
      studio: data.studio_data,
    });
  }
}
