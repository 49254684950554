import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

/**
 * Dialog component.
 */
@Component({
  selector: 'gymautoc-app-dialog',
  templateUrl: './app-dialog.component.html',
  styleUrls: ['./app-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppDialogComponent {

  /**
   * Dialog title.
   */
  @Input() public title = '';

  constructor(public readonly dialogRef: MatDialogRef<AppDialogComponent>) {}

  /**
   * Close dialog.
   */
  public close(): void {
    this.dialogRef.close();
  }
}
