import { Component, ChangeDetectionStrategy, ViewEncapsulation, Input } from '@angular/core';
import { ThemePalette } from '@angular/material/core';

/**
 * App card component.
 */
@Component({
  selector: 'gymautoc-app-card',
  templateUrl: './app-card.component.html',
  styleUrls: ['./app-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class AppCardComponent {
  /** Card header color. */
  @Input() public color: ThemePalette;

  /** Use default padding for card content. */
  @Input() public padding = true;
}
