import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanLoad, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { CurrentUserService } from '@gymautoc/common/core/services/current-user.service';
import { routePaths } from '@gymautoc/common/core/utils/route-paths';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';

/**
 * Guard that checks that user is authenticated
 */
@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate, CanLoad {
  /**
   * @constructor
   */
  public constructor(
    protected readonly currentUserService: CurrentUserService,
    protected readonly router: Router,
  ) {
  }

  /** Determine if route could be achieved */
  public canActivate(
    _route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> {
    return this.check(state.url);
  }

  /**
   * @inheritDoc
   */
  public canLoad(): Observable<boolean | UrlTree> {
    return this.check();
  }

  /**
   * Guard logic.
   * Pass only authenticated users.
   */
  public guard(isAuthenticated: boolean, url: string): boolean | UrlTree {
    if (isAuthenticated) {
      return true;
    }

    return this.router.createUrlTree(routePaths.login, {
      queryParams: url ? { next: url } : undefined,
    });
  }

  /**
   * Check if user is authenticated in user service
   *
   * If user is not authenticated, he will be redirected to login page by
   * userService for further login.
   *
   * @param url url to be used for redirect after login.
   */
  protected check(url: string = ''): Observable<boolean | UrlTree> {
    return this.currentUserService.isAuthenticated$.pipe(
      first(),
      map((isAuthenticated) => this.guard(isAuthenticated, url)),
    );
  }
}
