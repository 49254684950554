<div class="dialog">
  <button
    mat-icon-button
    type="button"
    class="closeButton"
    (click)="close()"
  >
    <mat-icon>close</mat-icon>
  </button>

  <h4 mat-dialog-title><b>{{title}}</b></h4>

  <div mat-dialog-content class="dialog-content">
    <ng-content></ng-content>
  </div>
</div>
