<ng-container *ngIf="formControl$ | async as formControl">
  <ng-container *ngIf="shouldDisplayErrorMessage(formControl); else fakeBlock">
    <gymautoc-validation-message [errors]="formControl.errors">
    </gymautoc-validation-message>
  </ng-container>
  <ng-template #fakeBlock>
    <!-- Use fake block to avoid markup moving when error is occurred. -->
    <gymautoc-validation-message> </gymautoc-validation-message>
  </ng-template>
</ng-container>
