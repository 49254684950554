import { Injectable } from '@angular/core';

import { ToggleSubject } from '../rxjs/toggle-subject';

/**
 * Service for printing.
 */
@Injectable()
export class WindowPrintService {
  /**
   * Indicates if printing is started.
   */
  public readonly isPrinting$ = new ToggleSubject();

  /** Start print Classes */
  public startPrint(): void {
    this.isPrinting$.on();
  }

  /** Print when it has data */
  public printData(windowRef: Window | null): void {
    this.isPrinting$.on();
    setTimeout(() => {
      if (windowRef) {
        windowRef.print();
        windowRef.close();
      }
      this.isPrinting$.off();
    }, 1000); // We need this delay to ensure that data is rendered.
  }
}
