import { Component, ChangeDetectionStrategy } from '@angular/core';
import { Router } from '@angular/router';
import { UserType } from '@gymautoc/common/core/enums/user-type';
import { NavigationLink } from '@gymautoc/common/core/models/navigation-link';
import { filterNull } from '@gymautoc/common/core/rxjs/filter-null';
import { CurrentUserService } from '@gymautoc/common/core/services/current-user.service';
import { mainPageByUserType, routePaths } from '@gymautoc/common/core/utils/route-paths';
import { map, tap } from 'rxjs/operators';

const LINKS_MAP: Map<UserType, NavigationLink[]> = new Map([
  [UserType.Admin, [
    {
      text: 'Users',
      route: routePaths.users,
    },
    {
      text: 'Scheduler',
      route: routePaths.scheduler,
    },
    {
      text: 'Holidays',
      route: routePaths.holidays,
    },
  ]],
  [UserType.StudioManager, [
    {
      text: 'Instructors',
      route: routePaths.instructors,
    },
    {
      text: 'Students',
      route: routePaths.students,
    },
    {
      text: 'Classes',
      route: routePaths.classes,
    },
    {
      text: 'Studios',
      route: routePaths.studios,
    },
    {
      text: 'Scheduler',
      route: routePaths.scheduler,
    },
    {
      text: 'Holidays',
      route: routePaths.holidays,
    },
    {
      text: 'Payment History',
      route: routePaths.paymentHistory,
    },
  ]],
  [UserType.Staff, [
    {
      text: 'Students',
      route: routePaths.students,
    },
    {
      text: 'Classes',
      route: routePaths.classes,
    },
    {
      text: 'Scheduler',
      route: routePaths.scheduler,
    },
    {
      text: 'Holidays',
      route: routePaths.holidays,
    },
  ]],
]);

/**
 * Admin layout component.
 */
@Component({
  selector: 'gymautow-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LayoutComponent {
  /** Sidebar navigation links. */
  public readonly links$ = this.currentUserService.currentUser$.pipe(
    filterNull(),
    map(user => user.type),
    tap(type => {
      const { url } = this.router;
      // We don't have a home page. That's why we have to redirect user to his main page.
      if (url === routePaths.home[0]) {
        this.router.navigate(mainPageByUserType[type]);
      }
    }),
    map(type => LINKS_MAP.get(type) || []),
  );

  /** Studio name */
  public readonly studioName$ = this.currentUserService.studioName$;

  /**
   * @constructor
   */
  public constructor(
    private readonly currentUserService: CurrentUserService,
    private readonly router: Router,
  ) {}

}
