import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AppConfigService } from '../services/app-config.service';
import { TokenService } from '../services/token.service';

const NO_INTERCEPT_URL_PATHS = [
  '/api/v1/auth/login/',
  '/api/v1/auth/password-reset/',
  '/api/v1/auth/password-reset-confirm/',
];

/**
 * Interceptor to add access token to requests.
 */
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  /**
   * @constructor
   * @param appConfigService - Configuration service.
   */
  constructor(
    private readonly appConfigService: AppConfigService,
    private readonly tokenService: TokenService,
  ) {}

  /**
   * Appends bearer token.
   * @inheritdoc
   */
  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.shouldInterceptToken(req.url)) {
      const token = this.tokenService.getToken(); // Obtain token from a storage.
      const newReq = req.clone({
        headers: req.headers.set('Authorization', `Token ${token}`),
      });

      return next.handle(newReq);
    }

    // Do nothing.
    return next.handle(req);
  }

  /**
   * Checks if a request is for authorization or refresh token.
   * @param url - Request url.
   */
  private shouldInterceptToken(url: string): boolean {
    return (
      url.startsWith(this.appConfigService.apiUrl) &&
      !NO_INTERCEPT_URL_PATHS.includes(new URL(url).pathname)
    );
  }
}
