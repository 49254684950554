import { Component, ChangeDetectionStrategy } from '@angular/core';

/** Container for loading spinner. */
@Component({
  selector: 'gymautoc-page-loader',
  templateUrl: './page-loader.component.html',
  styleUrls: ['./page-loader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageLoaderComponent {
}
