import { UserRole } from '../enums/user-role';

import { Studio } from './studio';
import { User } from './user';

/**
 * Studio user.
 */
export class StudioUser extends User {
  /** User permissions towards studio. */
  public role: UserRole;

  /** Studio. */
  public studio: StudioUserStudioData;

  /**
   * @constructor
   */
  public constructor(data: ConstructorInitArg<StudioUser>) {
    super(data);
    this.role = data.role;
    this.studio = data.studio;
  }
}

export type StudioUserStudioData = Pick<Studio, 'id' | 'name'>;
