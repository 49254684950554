import { ConnectedPosition, Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { Directive, ElementRef, HostListener, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';

const FILTERS_POSITION: ConnectedPosition = {
  originX: 'center',
  originY: 'bottom',
  overlayX: 'center',
  overlayY: 'top',
  offsetY: 5,
};

/**
 * Overlay card directive.
 */
@Directive({
  selector: '[gymautocConnectedOverlay]',
})
export class ConnectedOverlayDirective implements OnInit {
  private overlayRef!: OverlayRef;

  /** Overlay container. */
  @Input()
  public overlayContent!: TemplateRef<unknown>;

  private isHostAttached = false;

  /** @constructor */
  public constructor(
    private readonly elementRef: ElementRef,
    private readonly viewContainerRef: ViewContainerRef,
    private readonly overlay: Overlay,
  ) { }

  /** @inheritdoc */
  public ngOnInit(): void {
    const strategy = this.overlay.position().flexibleConnectedTo(this.elementRef)
      .withPositions([FILTERS_POSITION]);

    const config = new OverlayConfig({
      positionStrategy: strategy,
      hasBackdrop: true,
      backdropClass: 'transparent-backdrop',
    });

    this.overlayRef = this.overlay.create(config);
    this.overlayRef.backdropClick().subscribe(() => {
      this.overlayRef.detach();
      this.isHostAttached = false;
    });
  }

  /** Handle click. */
  @HostListener('click')
  public show(): void {
    // We need this to prevent second attach to overlayRef due to 'Enter' key press.
    // For example it's needed when we have form inside connected overlay.
    if (!this.isHostAttached) {
      this.overlayRef.attach(new TemplatePortal(this.overlayContent, this.viewContainerRef));
      this.isHostAttached = true;
    }
  }
}
