import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UserType } from '@gymautoc/common/core/enums/user-type';

import { LayoutComponent } from './layouts/layout.component';
import { AuthPageComponent } from './modules/auth/auth-page.component';
import { AuthGuard } from './modules/auth/guards/auth.guard';
import { RestrictUserTypesGuard } from './modules/auth/guards/restrict-user-types.guard';
import { NotFoundPageComponent } from './modules/shared/components/not-found-page/not-found-page.component';

export const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'users',
        loadChildren: () => import('./modules/users/users.module').then(m => m.UsersModule),
        canActivate: [RestrictUserTypesGuard],
        data: {
          userTypes: [UserType.Admin],
        },
      },
      {
        path: 'studios',
        loadChildren: () => import('./modules/studios/studios.module').then(m => m.StudiosModule),
        canActivate: [RestrictUserTypesGuard],
        data: {
          userTypes: [UserType.StudioManager],
        },
      },
      {
        path: 'classes',
        loadChildren: () => import('./modules/classes/classes.module').then(m => m.ClassesModule),
        canActivate: [RestrictUserTypesGuard],
        data: {
          userTypes: [UserType.StudioManager, UserType.Staff],
        },
      },
      {
        path: 'scheduler',
        loadChildren: () =>
          import('./modules/scheduler/scheduler.module').then(m => m.SchedulerModule),
        canActivate: [RestrictUserTypesGuard],
        data: {
          userTypes: [UserType.Admin, UserType.StudioManager, UserType.Staff],
        },
      },
      {
        path: 'instructors',
        loadChildren: () =>
          import('./modules/instructors/instructors.module').then(m => m.InstructorsModule),
        canActivate: [RestrictUserTypesGuard],
        data: {
          userTypes: [UserType.StudioManager],
        },
      },
      {
        path: 'students',
        loadChildren: () =>
          import('./modules/students/students.module').then(m => m.StudentsModule),
        canActivate: [RestrictUserTypesGuard],
        data: {
          userTypes: [UserType.StudioManager, UserType.Staff],
        },
      },
      {
        path: 'holidays',
        loadChildren: () =>
          import('./modules/holidays/holidays.module').then(m => m.HolidaysModule),
        canActivate: [RestrictUserTypesGuard],
        data: {
          userTypes: [UserType.Admin, UserType.StudioManager],
        },
      },
      {
        path: 'payment-history',
        loadChildren: () =>
          import('./modules/payment-history/payment-history.module').then(m => m.PaymentHistoryModule),
        canActivate: [RestrictUserTypesGuard],
        data: {
          userTypes: [UserType.StudioManager],
        },
      },
      {
        path: 'not-found',
        component: NotFoundPageComponent,
      },
    ],
  },
  {
    path: 'auth',
    component: AuthPageComponent,
    loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule),
  },
  { path: '**', redirectTo: 'auth' },
];

/** App routing module. */
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
