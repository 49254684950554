import { Component, ChangeDetectionStrategy } from '@angular/core';
import { WindowPrintService } from '@gymautoc/common/core/services/window-print.service';

/**
 * Printable container component.
 */
@Component({
  selector: 'gymautoc-printable-container',
  templateUrl: './printable-container.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [WindowPrintService],
})
export class PrintableContainerComponent {
  /** Is printing. */
  public readonly isPrinting$ = this.printService.isPrinting$;

  /** @constructor */
  public constructor(
    private readonly printService: WindowPrintService,
  ) { }

  /** Open container for printing. */
  public startPrint(): void {
    this.printService.startPrint();
  }

  /** Print data. */
  public print(windowRef: Window): void {
    this.printService.printData(windowRef);
  }

}
