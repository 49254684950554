<mat-sidenav-container fullscreen class="example-container">
  <mat-sidenav
    opened
    mode="side"
    #sidenavBar
    [@onSidebarChange]="(sidebarOpened$ | async) ? 'open' : 'close'"
  >
    <ng-container *ngIf="customSidebar; else sidebar" select="[sidebar-content]"></ng-container>
    <ng-template #sidebar>
      <div *ngIf="showSidebarContent$ | async">
        <div class="user-info">
          <div class="studio-name">
            <ng-container *ngIf="studioName">{{ studioName }}</ng-container>
          </div>
          <gymautow-user-info></gymautow-user-info>
        </div>
        <hr />
        <div class="navigation">
          <a
            *ngFor="let link of links"
            mat-button
            routerLinkActive="active"
            [routerLink]="link.route"
          >
            {{ link.text }}
          </a>
        </div>
      </div>
    </ng-template>
  </mat-sidenav>
  <mat-sidenav-content [@onMainContentChange]="(sidebarOpened$ | async) ? 'open' : 'close'">
    <ng-container *ngIf="customPage; else content" select="[page-content]"></ng-container>
    <ng-template #content>
      <div class="page-content">
        <router-outlet></router-outlet>
      </div>
    </ng-template>
  </mat-sidenav-content>
</mat-sidenav-container>

<div class="toggle-sidenav-button">
  <button
    (click)="onSidenavToggle()"
    mat-mini-fab
    color="primary"
    aria-label="Example icon button with a menu icon"
  >
    <mat-icon>menu</mat-icon>
  </button>
</div>
