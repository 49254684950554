/**
 * Environment variables passed from webpack (custom-webpack-config.js).
 */
declare const $ENV: {
  /** Production build or not */
  PRODUCTION: string,

  /** Backend API url */
  API_URL: string;

  /** Stripe key */
  STRIPE_KEY: string;
};

export const environment = {
  production: $ENV.PRODUCTION === 'TRUE',
  apiUrl: $ENV.API_URL,
  stripeKey: $ENV.STRIPE_KEY,
};
