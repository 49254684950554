import { Directive, Self } from '@angular/core';
import { MatSelectSearchComponent } from 'ngx-mat-select-search';

/**
 * Custom ngx-mat-select-search wrapper directive.
 */
@Directive({
  selector: 'ngx-mat-select-search[gymautocSelectSearch]',
})
export class CustomSelectSearchDirective {
  public constructor(@Self() instance: MatSelectSearchComponent) {
    instance.placeholderLabel = 'Search';
    instance.noEntriesFoundLabel = 'No options found';
  }
}
