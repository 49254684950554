import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap, map } from 'rxjs/operators';

import { StorageService } from './storage.service';

/** Storage key for token */
const TOKEN_STORAGE_KEY = 'auth_token';

/** TokenData. */
export interface TokenData {
  /** Access token. */
  value: string;
  /** When token should be remove from store. */
  expiry: string;
}

/**
 * Token service.
 * Provides methods to get and set token.
 */
@Injectable({
  providedIn: 'root',
})
export class TokenService {
  private readonly tokenValue$ = new BehaviorSubject<string>('');

  /** Async readonly token value */
  // tslint:disable-next-line: completed-docs
  public readonly token$ = this.storageService.get<{ value: string, expiry: string }>(TOKEN_STORAGE_KEY).pipe(
    map(token => {
      if (token) {
        const date = new Date(token.expiry);
        if (date < new Date()) {
          return null;
        }

        this.tokenValue$.next(token.value);
        return token.value;
      }
      return null;
    }),
  );

  /**
   * @constructor
   */
  public constructor(private storageService: StorageService) {
  }

  /** Set new token value */
  public setToken(tokenData: TokenData): Observable<void> {
    return this.storageService
      .set(TOKEN_STORAGE_KEY, { ...tokenData })
      .pipe(
        tap(() => this.tokenValue$.next(tokenData.value)),
      );
  }

  /** Get token value synchronously. */
  public getToken(): string {
    return this.tokenValue$.value;
  }

  /** Clear token value */
  public clear(): Observable<void> {
    return this.storageService
      .set(TOKEN_STORAGE_KEY, '')
      .pipe(tap(() => this.tokenValue$.next('')));
  }
}
