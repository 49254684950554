import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { defer, merge, Observable, ReplaySubject, throwError } from 'rxjs';
import { catchError, map, mapTo, shareReplay, switchMap, tap } from 'rxjs/operators';

import { UserProfile } from '../models/profile';
import { StudioUser } from '../models/studio-user';
import { filterNull } from '../rxjs/filter-null';

import { AppConfigService } from './app-config.service';
import { AuthDto } from './mappers/dto/auth-dto';
import { StudioUserDto } from './mappers/dto/studio-user-dto';
import { UserProfileDto } from './mappers/dto/user-profile-dto';
import { StudioUserMapper } from './mappers/studio-user.mapper';
import { UserProfileMapper } from './mappers/user-profile-mapper';
import { TokenService } from './token.service';

/**
 * User service.
 * Provides ability to work with current application user.
 */
@Injectable({
  providedIn: 'root',
})
export class CurrentUserService {
  private readonly userValue$ = new ReplaySubject<UserProfile | null>(1);
  private readonly userFromStorage$ = this.tokenService.token$.pipe(
    switchMap(token => {
      if (!token) {
        return throwError('Unauthorized');
      }
      return this.getCurrentUser();
    }),
    catchError(() => this.logout().pipe(mapTo(null))),
  );

  /**
   * Current user.
   * Emits `null` if current user is not authenticated.
   */
  public readonly currentUser$ = merge(
    this.userFromStorage$,
    this.userValue$,
  ).pipe(
    shareReplay(1),
  );

  /** Name of user studio. */
  public readonly studioName$ = this.currentUser$.pipe(
    filterNull(),
    map(user => user.studio?.name || null),
    shareReplay({ refCount: true, bufferSize: 1 }),
  );

  /** Is current user authenticated. */
  public readonly isAuthenticated$ = this.currentUser$.pipe(
    map(user => user !== null),
    shareReplay(1),
  );

  /** Studio user profile. */
  public readonly studioUserProfile$ = defer(() => this.getStudioUserProfile()).pipe(
    shareReplay({ refCount: true, bufferSize: 1 }),
  );

  private readonly profileUrl = new URL('users/users/profile/', this.config.apiUrl).toString();
  private readonly studioUserProfileUrl = new URL('users/studio-users/profile/', this.config.apiUrl).toString();

  /** @constructor */
  public constructor(
    private readonly tokenService: TokenService,
    private readonly config: AppConfigService,
    private readonly http: HttpClient,
    private readonly profileMapper: UserProfileMapper,
    private readonly studioUserMapper: StudioUserMapper,
  ) {
  }

  /**
   * Get user by id.
   * @returns User.
   */
  public getCurrentUser(): Observable<UserProfile> {
    return this.http.get<UserProfileDto>(this.profileUrl).pipe(
      filterNull(),
      map(response => this.profileMapper.fromDto(response)),
    );
  }

  /**
   * Get studio user profile.
   * @returns User.
   */
  private getStudioUserProfile(): Observable<StudioUser> {
    return this.http.get<StudioUserDto>(this.studioUserProfileUrl).pipe(
      filterNull(),
      map(response => this.studioUserMapper.fromDto(response)),
    );
  }

  /**
   * Set token and fetch user data
   * @param token new token value
   */
  public completeAuthorizationProcess(authDto: AuthDto): Observable<UserProfile> {
    return this.tokenService.setToken({
      value: authDto.token,
      expiry: authDto.expiry,
    }).pipe(
      map(() => this.profileMapper.fromDto(authDto.user)),
      tap(user => this.userValue$.next(user)),
      filterNull(),
    );
  }

  /** Remove the token and clear the current user. */
  public logout(): Observable<void> {
    return this.tokenService.clear().pipe(
      tap(() => this.userValue$.next(null)),
    );
  }
}
