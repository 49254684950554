import { Route } from '@angular/router';

/** Information about Breadcrumb */
export class Breadcrumb {
  /**
   * Id of breadcrumb
   * Allow you to replace name of breadcrumb in runtime.
   */
  public id?: string;

  /** Display name */
  public displayName: string;

  /** Is Terminal route */
  public terminal: boolean;

  /** Url */
  public url: string;

  /** Route */
  public route?: Route;

  constructor(data: ConstructorInitArg<Breadcrumb>) {
    this.id = data.id;
    this.displayName = data.displayName;
    this.terminal = data.terminal;
    this.url = data.url;
    this.route = data.route;
  }
}
