import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

/** Container for loading spinner. */
@Component({
  selector: 'gymautoc-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoaderComponent {
  /** Loader circle diameter. */
  @Input()
  public diameter = 100;
}
