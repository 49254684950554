import { Component, ChangeDetectionStrategy, ChangeDetectorRef, Optional, Self, Input } from '@angular/core';
import { FormControl, NgControl } from '@angular/forms';
import { ControlValueAccessorBase } from '@gymautoc/common/core/utils/control-value-accessor-base';
import { DestroyableComponent } from '@gymautoc/common/core/utils/destroyable';
import { Moment } from 'moment';

/**
 * Date picker component.
 */
@Component({
  selector: 'gymautoc-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
@DestroyableComponent()
export class DatePickerComponent extends ControlValueAccessorBase<Moment> {
  /** Form control label. */
  @Input()
  public label: string | null = null;

  /** Form control placeholder. */
  @Input()
  public placeholder = 'Select date';

  /** Is required. */
  @Input()
  public required = false;

  /** Is readonly. */
  @Input()
  public readonly = false;

  /** Max value for datepicker. */
  @Input()
  public max: Date | null = null;

  /** Min value for datepicker. */
  @Input()
  public min: Date | null = null;

  /** Is form control disabled. */
  public get isDisabled(): boolean {
    return this.readonly || Boolean(this.control.disabled);
  }

  /** From control. */
  public get formControl(): FormControl {
    return this.control.control as FormControl;
  }

  /** @constructor */
  public constructor(
    cdr: ChangeDetectorRef,
    @Self() @Optional() private readonly control: NgControl,
  ) {
    super(cdr);
    this.control.valueAccessor = this;
  }
}
