import { ChangeDetectionStrategy, Component } from '@angular/core';

import { Breadcrumb } from '../../breadcrumb';
import { BreadcrumbsService } from '../../breadcrumbs.service';

/** Display current breadcrumbs */
@Component({
  selector: 'gymautoc-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BreadcrumbsComponent {
  /** Breadcrumbs */
  public readonly breadcrumbs$ = this.breadcrumbsService.breadcrumbs$;

  public constructor(
    private readonly breadcrumbsService: BreadcrumbsService,
  ) {
  }

  /** Track By func for breadcrumb */
  public trackByBreadcrumb(_index: number, crumb: Breadcrumb): string {
    return crumb.url;
  }
}
